import React, { ReactElement } from "react"
import { connect } from "redux-zero/react"
import VehicleDisplay from "@hoc/VehicleDisplay"
const styles = require("./index.module.css")

interface ICheckoutContainerProps {
  cart?: any
}

function CheckoutContainer({ cart }: ICheckoutContainerProps): ReactElement {
  React.useEffect(() => {})
  return (
    <div>
      {cart ? (
        <VehicleDisplay vehicle={cart[0]} />
      ) : (
        <>
          <div className="pt-16 w-full flex flex-row justify-center items-center">
            <div className="text-center">
              <b className="text-3xl font-bold">Cart is empty!</b>
              <br />
              <a href="/">Click here to search</a>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const mapToProps = (state: any) => ({
  cart: state.checkout__cart,
})

export default connect(mapToProps)(CheckoutContainer)
