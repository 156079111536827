import CheckoutContainer from "@containers/Checkout"
import DefaultLayout from "@layouts/default"
import React, { ReactElement } from "react"

function Checkout(): ReactElement {
  return (
    <>
      <DefaultLayout pageTitle="Home">
        <CheckoutContainer />
      </DefaultLayout>
    </>
  )
}

export default Checkout
